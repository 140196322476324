import React, { useState, useCallback, useEffect } from "react";
import { Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import { Form } from "antd";
import defaultimage from "../../assets/images/Icons/defaultflagicon.png";

const API_URL = "flights/updatedAirPort/search/";
const AirportAutoComplete = (props) => {
  console.log(props,"autocomp");
  // { console.log("Iam Also") }
  const [hasFetched, setHasFetched] = useState(false); // Track API call status
  const { Option } = Select;
  const [options, setOptions] = useState({
    data: [],
    value: [],
    fetching: false,
  });

  useEffect(() => {
    let value = "";
    if (props.isfieldKey) {
      value = props.refName.current[props.fieldKey].props.value;
    } else {
      value = props.refName.current.props.value;
    }

    if (!value || value === "" || value === " ") {
      return;
    }

    if (!hasFetched) {
      fetchData(value);
    }
  }, [
    props.isfieldKey
      ? props.refName.current[props.fieldKey]
      : props.refName.current,
    props.dataFlip,
  ]);

  const check = () => {
    if (props.focusRef) {
      if (props.isfieldKey)
        props.handleOnSubmit(props.focusRef, props.isfieldKey, props.fieldKey);
      else props.handleOnSubmit(props.focusRef);
    }
  };

  const debounceOnChange = useCallback(debounce(fetchData, 400), []);
  // console.log(debounceOnChange, "Iam also");

  const createOptions = (results) => {
    let arr = [];

    results.forEach((result) => {
      arr.push({
        value: result._source.code,
        display: result._source.suggestionWithTags,
        _class: "parent",
        label: result.suggestion,
      });

      if (result._type === "multiport") {
        result._source.airports_data.forEach((result_2) => {
          arr.push({
            value: result_2._source.code,
            display: result_2._source.suggestionWithTags,
            _class: "child",
            label: result_2.suggestion,
          });
        });
      }
    });

    return arr;
  };

  function fetchData(value) {
    if (hasFetched) return; // Stop further API calls if already fetched successfully

    if (value === "" || value === " ") {
      return;
    }

    setOptions({ data: [], fetching: true });
    APIClient.get(`${API_URL}${value}`)
      .then((res) => {
        if (res.statusCode === 200 && res) {
          setOptions({ data: res.data, fetching: false });
          setHasFetched(true); // Mark as fetched successfully
          return;
        }
      })
      .catch((error) => {
        console.error("error", error);
        setOptions({ data: [], fetching: false });
      });
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const getflag = (code) => {
   
    if (code === "yu" || code === "tp"||code === "i" ||code === ""|| code === "#FIELD!"|| code === "ac"|| code === "cs"|| code === "fp"|| code === "oh" ){
      let codeflag = defaultimage;
      return codeflag;
    } else {
      let codeflag = require(`../../assets/country-flag-svg/${code}.svg`).default;
      return codeflag;
    }
  }

  return (
    <Form.Item
      {...props.formItemProps}
      className="airport_auto_complete notranslate"
    >
      <Select
        style={{ width: "100%" }}
        showSearch
        ref={(el) => {
          props.isfieldKey
            ? (props.refName.current[props.fieldKey] = el)
            : (props.refName.current = el);
        }}
        notFoundContent={options.fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={debounceOnChange}
        onChange={props.onChange}
        {...props.selectProps}
        onSelect={check}
        dropdownClassName={"airpot_names_dropdown"}
        dropdownStyle={{ width: "100%" }}
        className="notranslate"
      >
        {options.data.map((d, index) => (
          <Option
            className="notranslate"
            value={d.AirportCode}
            key={"key" + index}
          >
            <div className="d-flex align-items-center justify-content-between">
              <i className="fa fa-plane forplace-wor-dropdownflight"></i>
              <div className="for-elepsis-work-dropdownflight">
                <p className="m-0 for-elepsis-work-dropdownflight">
                  {d.City}, {d.Country}
                </p>
                <p
                  className="m-0 city-column for-elepsis-work-dropdownflight"
                  style={{ fontSize: "11px", color: "#9B9B9B" }}
                >
                  {d.AirportDesc}
                </p>
              </div>
              <div className="city-column">
                <p className="m-0" style={{ color: "#9B9B9B" }}>
                  <span> {d.AirportCode}</span>
                  &nbsp;
                  <img
                    src={d?.CountryCode ? getflag(d?.CountryCode?.toLowerCase()) : ""}
                    width="20"
                    alt={d?.Country}
                  ></img>
                </p>
              </div>
            </div>
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default AirportAutoComplete;
