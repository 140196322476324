// import { PlusOutlined } from "@ant-design/icons";
// import React, { useState } from "react";
// import {
//   Button,
//   Card,
//   Checkbox,
//   Col,
//   DatePicker,
//   Form,
//   Input,
//   message,
//   Modal,
//   Row,
//   Select,
//   Upload,
// } from "antd";

// import { Link } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";
// import APIClient from "../../helpers/ApiClient";
// import moment from "moment";
// import Login from "../Login/Login";
// import OTP from "../OTP/Otp";
// import "./AgentRegistration.scss";

// const BASE = process.env.REACT_APP_BASE_URL;
// const uploadActionUrl = BASE + "media/upload/multipleImages";
// const prefixSelector = "+91";
// const { Option } = Select;

// const ImageUpload = ({
//   category,
//   fileList,
//   setFileList,
//   formName,
//   limit,
//   limitSize,
// }) => {
//   const [isUploading, setIsUploading] = useState(false);
//   const [previewVisible, setPreviewVisible] = useState(false);
//   const [previewImage, setPreviewImage] = useState("");
//   const [previewTitle, setPreviewTitle] = useState("");

//   const uploadLogoButton = !isUploading ? (
//     <div>
//       <PlusOutlined />
//       <div className="ant-upload-text">Upload </div>
//     </div>
//   ) : (
//     <div className="ant-upload-text">Loading... </div>
//   );

//   function beforeUpload(file) {
//     const checkJpgOrPng =
//       file.type === "application/pdf" ||
//       file.type === "image/png" ||
//       file.type === "image/jpeg" ||
//       file.type === "image/jpg";
//     if (!checkJpgOrPng) {
//       message.error("You can only upload jpg, pdf,jpeg and png file!");
//     }

//     const checkFileSize = file.size < 1126400;
//     if (!checkFileSize) {
//       message.error(" Image must be smaller than 1Mb!");
//     }

//     return checkJpgOrPng && checkFileSize;
//   }

//   function getImages(file) {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => resolve(reader.result);
//       reader.onerror = (error) => reject(error);
//     });
//   }

//   function handleChange(info) {
//     setFileList(info.fileList);
//     if (info.file.status === "uploading") {
//       setIsUploading(true);
//       return;
//     }
//     if (info.file.status === "removed") {
//       setIsUploading(false);
//     }
//     if (info.file.status === "error") {
//       setIsUploading(false);
//       return;
//     }
//     if (info.file.status === "done") {
//       setIsUploading(false);
//     }
//   }

//   const handlePreview = async (file) => {
//     if (!file.url && !file.preview) {
//       file.preview = await getImages(file.originFileObj);
//     }
//     setPreviewVisible(true);
//     setPreviewImage(file.url || file.preview);

//     setPreviewTitle(file.name);
//   };

//   const handleCancel = () => {
//     setPreviewVisible(false);
//   };

//   return (
//     <>
//       <Form.Item name={formName}>
//         <Upload
//           name="image"
//           listType="picture-card"
//           className="avatar-uploader"
//           showUploadList={true}
//           fileList={fileList}
//           action={uploadActionUrl}
//           onChange={handleChange}
//           onPreview={handlePreview}
//           data={{ category: category }}
//           beforeUpload={beforeUpload}
//         >
//           {limit
//             ? fileList
//               ? fileList.length >= limitSize
//                 ? null
//                 : uploadLogoButton
//               : uploadLogoButton
//             : uploadLogoButton}
//         </Upload>
//       </Form.Item>
//       <Modal
//         destroyOnClose={true}
//         visible={previewVisible}
//         title={previewTitle}
//         footer={null}
//         onCancel={handleCancel}
//       >
//         <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
//       </Modal>
//     </>
//   );
// };
// const AgentRegistration = (props) => {
//   const [pan, setPan] = useState([]);
//   const [gst, setGst] = useState([]);
//   const [otheruploads, setOtheruploads] = useState([]);
//   const [userId, setUserId] = useState(-1);
//   const [adhar, setAdhar] = useState([]);
//   const [buttonDisabled, setButtonDisabled] = useState(true);
//   const [form] = Form.useForm();

//   const [showSuccessMessage, setShowSuccessMessage] = useState(false);
//   const [loginModal, setLoginModal] = useState(false);

//   const onFinish = (values) => {
//     // console.log(values,"agent-reg");
//     let adharPath = "";

//     if (values.AdhaarCardUpload) {
//       if (values.AdhaarCardUpload.fileList.length > 0) {
//         if (values.AdhaarCardUpload.fileList[0].response.status === 200) {
//           adharPath =
//             values.AdhaarCardUpload.fileList[0].response.data.filepaths[0];
//         }
//       }
//     }
//     let PANUpload = "";

//     if (values.PANUpload) {
//       if (values.PANUpload.fileList.length > 0) {
//         if (values.PANUpload.fileList[0].response.status === 200) {
//           PANUpload = values.PANUpload.fileList[0].response.data.filepaths[0];
//         }
//       }
//     }
//     let GSTUpload = "";

//     if (values.GSTUpload) {
//       if (values.GSTUpload.fileList.length > 0) {
//         if (values.GSTUpload.fileList[0].response.status === 200) {
//           GSTUpload = values.GSTUpload.fileList[0].response.data.filepaths[0];
//         }
//       }
//     }
//     let otherupload = "";

//     if (values.OtherUploads) {
//       if (values.OtherUploads.fileList.length > 0) {
//         if (values.OtherUploads.fileList[0].response.status === 200) {
//           otherupload =
//             values.OtherUploads.fileList[0].response.data.filepaths[0];
//         }
//       }
//     }

//     let regObj = {
//       Title: values.title,
//       FirstName: values.firstname,
//       LastName: values.lastname,
//       Email: values.email,
//       DialingCode: prefixSelector,
//       Mobile: values.phoneNumber,
//       AgencyName: values.agencyname,
//       Password: values.password,
//       DOB: values.dob,
//       Gender: values.gender,
//       StoreAddress: values.storeaddress,
//       RegisterdAddress: values.registeredaddress,
//       PAN: values.pannumber ? values.pannumber : "",
//       PANUpload: PANUpload ? PANUpload : "",
//       AdhaarCard: values.Aadhaar ? values.Aadhaar : "",
//       AdhaarCardUpload: adharPath ? adharPath : "",
//       GSTUpload: GSTUpload ? GSTUpload : "",
//       LicenseNumber: values.LicenseNumber ? values.LicenseNumer : "",
//       VoterId: values.VoterId ? values.VoterId : "",
//       Passport: values.Passport ? values.Passport : "",
//       PassportUpload: values.PassportUpload ? values.PassportUpload : "",
//       OtherDocumentsUpload: otherupload ? otherupload : "",
//       Comments: values.comments ? values.comments : "",
//       Role: 5,
//       Status: 0,
//       Services: [1, 2, 3, 4, 5, 6],
//       DeviceOS: 1,
//       ApplicationType: 1,
//       GSTDetails: {
//         GSTCompanyName: "",
//         GSTINNumber: "",
//         GSTAdress: "",
//         GSTPhone: "",
//         GSTEmail: "",
//       },
//       CreadtedBy: 0,
//       CreatedDate: 0,
//       DeviceToken: "string",
//       DeviceType: "string",
//       FirBaseToken: "string",
//       DeviceOSVersion: "string",
//     };
//     Submitdata(regObj);
//   };
//   const Submitdata = (data) => {
//     APIClient.post("admin/b2b/signUp", data)
//       .then((response) => {
//         if (response.status == 200) {
//           setUserId(response.data.userDetails.UserId);
//           setShowSuccessMessage(true);
//           form.resetFields();
//           setAdhar([]);
//           setPan([]);
//           setOtheruploads([]);
//           setGst([]);
//           message.success(response.message);
//         } else if (response.status == 500) {
//           message.error("Duplicate Entry", 3);
//         } else if (response.status == 404) {
//           message.error("Not Found", 3);
//         } else if (response.status == 409) {
//           message.error(response.message);
//         } else {
//           message.error("Something Went Wrong", 3);
//         }
//       })

//       .catch((error) => {
//         console.error(error);
//       });
//   };

//   const disabledOriginDate = (currentDate) => {
//     return currentDate > moment().startOf("day");
//   };

//   const handleOk = (e) => {
//     setShowSuccessMessage(false);
//   };

//   const handleCancel = (e) => {
//     setShowSuccessMessage(false);
//   };
//   const enablebutton = (e) => {
//     e.target.checked == true
//       ? setButtonDisabled(false)
//       : setButtonDisabled(true);
//   };

//   const openlogin = () => {
//     setLoginModal(true);
//   };

//   const handleOkLogin = (e) => {
//     setLoginModal(false);
//   };

//   const handleCancelLogin = (e) => {
//     setLoginModal(false);
//   };
//   function handleCaptcha(value) {
//     if (value) {
//       setButtonDisabled(false);
//     } else {
//       setButtonDisabled(true);
//     }
//   }
//   return (
//     <div>
//       <div className="register-section">
//         <div className="reg-container">
//           <h2> Partner Register account and travel with ease</h2>
//           <Card className="register-card">
//             <Form
//               form={form}
//               onFinish={onFinish}
//               size="large"
//             >
//               <Row gutter={[16, 16]} className="agent-login-20">
//                 <Col md={6} sm={12} xs={24} className="right-panel">
//                   <Form.Item
//                     name="title"
//                     rules={[{ required: true, message: "Required!" }]}
//                   >
//                     <Select placeholder="Title">
//                       <Option value="Mr">Mr</Option>
//                       <Option value="Ms">Ms</Option>
//                       <Option value="Mrs">Mrs</Option>
//                     </Select>
//                   </Form.Item>
//                 </Col>

//                 <Col md={6} sm={12} xs={24}>
//                   <Form.Item
//                     name="firstname"
//                     rules={[{ required: true, message: "Required!" }]}
//                   >
//                     <Input placeholder="First Name" />
//                   </Form.Item>
//                 </Col>

//                 <Col md={6} sm={12} xs={24}>
//                   <Form.Item
//                     name="lastname"
//                     rules={[{ required: true, message: "Required!" }]}
//                   >
//                     <Input placeholder="Last Name" />
//                   </Form.Item>
//                 </Col>
//                 <Col md={6} sm={12} xs={24}>
//                   <Form.Item
//                     name="email"
//                     rules={[
//                       { required: true, message: "Required!" },
//                       {
//                         type: "email",
//                         message: "The input is not valid E-mail!",
//                       },
//                     ]}
//                   >
//                     <Input placeholder="Enter Your Email" />
//                   </Form.Item>
//                 </Col>

//                 <Col md={6} sm={12} xs={24}>
//                   <Form.Item
//                     name="phoneNumber"
//                     rules={[
//                       {
//                         required: true,
//                         message: "Required!",
//                       },
//                       {
//                         minLength: 10,
//                         maxLength: 10,
//                         pattern: "^[0-9]{10}$",
//                         message: "Must be 10 digits",
//                       },
//                     ]}
//                   >
//                     <Input
//                       addonBefore={prefixSelector}
//                       placeholder="Enter Mobile Number"
//                     />
//                   </Form.Item>
//                 </Col>
//                 <Col md={6} sm={12} xs={24}>
//                   <Form.Item
//                     name="password"
//                     rules={[{ required: true, message: "Required!" }]}
//                   >
//                     <Input.Password placeholder="Enter Password" />
//                   </Form.Item>
//                 </Col>
//                 <Col md={6} sm={12} xs={24}>
//                   <Form.Item
//                     name="dob"
//                     rules={[{ required: true, message: "Required!" }]}
//                   >
//                     <DatePicker
//                       format="DD-MM-YYYY"
//                       style={{ width: "100%" }}
//                       disabledDate={disabledOriginDate}
//                       placeholder="DOB"
//                     />
//                   </Form.Item>
//                 </Col>
//                 <Col md={6} sm={12} xs={24} className="right-panel">
//                   <Form.Item
//                     name="gender"
//                     rules={[{ required: true, message: "Required!" }]}
//                   >
//                     <Select placeholder="Gender">
//                       <Option value="1">Male</Option>
//                       <Option value="2">Female</Option>
//                       <Option value="3">Other</Option>
//                     </Select>
//                   </Form.Item>
//                 </Col>
//               </Row>

//               <Row gutter={[16, 16]} className="agent-login-21">
//                 <Col md={24} xs={24}>
//                   <h6 className="other-docrr agency-21-trip">
//                     Agency Information
//                   </h6>
//                 </Col>
//                 <Col md={8} sm={12} xs={24}>
//                   <Form.Item
//                     name="agencyname"
//                     rules={[{ required: true, message: "Required!" }]}
//                   >
//                     <Input placeholder="Agency Name" />
//                   </Form.Item>
//                 </Col>

//                 <Col md={8} sm={12} xs={24}>
//                   <Form.Item
//                     name="storeaddress"
//                     rules={[{ required: true, message: "Required!" }]}
//                   >
//                     <Input placeholder="Store Address" />
//                   </Form.Item>
//                 </Col>

//                 <Col md={8} sm={12} xs={24}>
//                   <Form.Item
//                     name="registeredaddress"
//                     rules={[{ required: true, message: "Required!" }]}
//                   >
//                     <Input placeholder="Registered Address" />
//                   </Form.Item>
//                 </Col>
//               </Row>
//               <Row
//                 gutter={[16, 16]}
//                 className="agent-login-22 details-agent-bb"
//               >
//                 <Col md={8} sm={12} xs={24}>
//                   <Form.Item
//                     name="pannumber"
//                     rules={[
//                       {
//                         pattern: "^[a-zA-Z0-9]{10}$",
//                         message: "Must be 10 digits AlphaNumeric Number",
//                       },
//                     ]}
//                   >
//                     <Input placeholder="PAN Number" />
//                   </Form.Item>
//                 </Col>

//                 <Col md={8} sm={12} xs={24}>
//                   <Form.Item
//                     name="Aadhaar"
//                     rules={[
//                       {
//                         pattern: "^[0-9]{12}$",
//                         message: "Must be 12 digits",
//                       },
//                     ]}
//                   >
//                     <Input placeholder="Aadhaar Number" />
//                   </Form.Item>
//                 </Col>
//                 <Col md={8} sm={12} xs={24}>
//                   <Form.Item
//                     name="gstnumber"
//                     rules={[
//                       {
//                         pattern: "^[a-zA-Z0-9]{15}$",
//                         message: "Must be 15 digits AlphaNumeric Number",
//                       },
//                     ]}
//                   >
//                     <Input placeholder="GST Number" />
//                   </Form.Item>
//                 </Col>
//               </Row>

//               <Row
//                 gutter={[16, 16]}
//                 className="agent-login-22 upload-item-agent"
//               >
//                 <div className="upload-img-11">
//                   <div>
//                     <h6 className="other-docrr agent-card-21 mb-3">
//                       PAN Details
//                     </h6>
//                     <ImageUpload
//                       category="pan"
//                       fileList={pan}
//                       setFileList={setPan}
//                       formName="PANUpload"
//                       limit={true}
//                       limitSize={1}
//                     />
//                   </div>
//                   <div>
//                     <h6 className="other-docrr agent-card-21 mb-3">
//                       Aadhaar Details
//                     </h6>

//                     <ImageUpload
//                       category="adhar"
//                       fileList={adhar}
//                       setFileList={setAdhar}
//                       formName="AdhaarCardUpload"
//                       limit={true}
//                       limitSize={1}
//                     />
//                   </div>
//                   <div>
//                     <h6 className="other-docrr agent-card-21 mb-3">
//                       GST Details
//                     </h6>
//                     <ImageUpload
//                       category="gst"
//                       fileList={gst}
//                       setFileList={setGst}
//                       formName="GSTUpload"
//                       limit={true}
//                       limitSize={1}
//                     />
//                   </div>
//                   <div>
//                     <h6 className="other-docrr agent-card-21 agency-22-trip mb-3">
//                       Other Documents
//                     </h6>
//                     <ImageUpload
//                       category="otheruploads"
//                       fileList={otheruploads}
//                       setFileList={setOtheruploads}
//                       formName="OtherUploads"
//                       limit={true}
//                       limitSize={6}
//                     />
//                   </div>
//                 </div>
//               </Row>

//               {/* <Row
//                 gutter={[16, 16]}
//                 className="agent-login-22 upload-item-agent"
//               >
//                 <Col md={6} sm={12} xs={24}>
//                   <h6 className="other-docrr agent-card-21 mb-3">
//                     PAN Details
//                   </h6>
//                   <ImageUpload
//                     category="pan"
//                     fileList={pan}
//                     setFileList={setPan}
//                     formName="PANUpload"
//                     limit={true}
//                     limitSize={1}
//                   />

//                   <Form.Item
//                     name="pannumber"
//                     rules={[
//                       {
//                         pattern: "^[a-zA-Z0-9]{10}$",
//                         message: "Must be 10 digits AlphaNumeric Number",
//                       },
//                     ]}
//                   >
//                     <Input placeholder="PAN Number" />
//                   </Form.Item>
//                 </Col>

//                 <Col md={6} sm={12} xs={24}>
//                   <h6 className="other-docrr agent-card-21 mb-3">
//                     Aadhaar Details
//                   </h6>

//                   <ImageUpload
//                     category="adhar"
//                     fileList={adhar}
//                     setFileList={setAdhar}
//                     formName="AdhaarCardUpload"
//                     limit={true}
//                     limitSize={1}
//                   />

//                   <Form.Item
//                     name="Aadhaar"
//                     rules={[
//                       {
//                         pattern: "^[0-9]{12}$",
//                         message: "Must be 12 digits",
//                       },
//                     ]}
//                   >
//                     <Input placeholder="Aadhaar Number" />
//                   </Form.Item>
//                 </Col>

//                 <Col md={6} sm={12} xs={24}>
//                   <h6 className="other-docrr agent-card-21 mb-3">
//                     GST Details
//                   </h6>
//                   <ImageUpload
//                     category="gst"
//                     fileList={gst}
//                     setFileList={setGst}
//                     formName="GSTUpload"
//                     limit={true}
//                     limitSize={1}
//                   />
//                   <Form.Item
//                     name="gstnumber"
//                     rules={[
//                       {
//                         pattern: "^[a-zA-Z0-9]{15}$",
//                         message: "Must be 15 digits AlphaNumeric Number",
//                       },
//                     ]}
//                   >
//                     <Input placeholder="GST Number" />
//                   </Form.Item>
//                 </Col>
//                 <Col md={6} xs={24} className="right-panel">
//                   <h6 className="other-docrr agent-card-21 agency-22-trip mb-3">
//                     Other Documents
//                   </h6>
//                   <ImageUpload
//                     category="otheruploads"
//                     fileList={otheruploads}
//                     setFileList={setOtheruploads}
//                     formName="OtherUploads"
//                     limit={true}
//                     limitSize={6}
//                   />
//                 </Col>
//               </Row> */}

//               {/* <Row className="agent-login-22">
//                 <Col md={24} xs={24} className="right-panel">
//                   <h6 className="other-docrr agency-22-trip mb-3">
//                     Other Documents
//                   </h6>
//                   <ImageUpload
//                     category="otheruploads"
//                     fileList={otheruploads}
//                     setFileList={setOtheruploads}
//                     formName="OtherUploads"
//                     limit={true}
//                     limitSize={6}
//                   />
//                 </Col>
//               </Row> */}
//               {/* <Row justify="center">
//                 <ReCAPTCHA
//                   sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
//                   onChange={handleCaptcha}
//                 />
//               </Row> */}
//               <Row justify="center" className="mt-2">
//                 <Form.Item
//                   name="remember"
//                   valuePropName="checked"
//                   rules={[
//                     {
//                       validator: (_, value) =>
//                         value ? Promise.resolve() : Promise.reject("Required"),
//                     },
//                   ]}
//                 >
//                   <Checkbox>
//                     <p className="mb-0">
//                       I agree with{" "}
//                       <Link to="/termsofconditions" target="_blank">
//                         {" "}
//                         Terms & conditions
//                       </Link>{" "}
//                       and{" "}
//                       <Link to="/privacypolicy" target="_blank">
//                         {" "}
//                         Privacy Policy
//                       </Link>
//                     </p>
//                   </Checkbox>
//                 </Form.Item>
//               </Row>
//               <Row justify="center">
//                 <Col md={24} xs={24}>
//                   <Form.Item className="register-btn-container">
//                     <Button
//                       // disabled={buttonDisabled}
//                       htmlType="submit"
//                       type="primary"
//                       onClick={() => form.submit()}
//                     >
//                       Register
//                     </Button>
//                   </Form.Item>
//                 </Col>
//               </Row>
//             </Form>
//             <Row>
//               <Col span={8}>
//                 <div className="otp-modal-container">
//                   <Modal
//                     visible={showSuccessMessage}
//                     onOk={handleOk}
//                     onCancel={handleCancel}
//                     footer={false}
//                     className="otp-modal"
//                   >
//                     <OTP
//                       userId={userId}
//                       setUserId={setUserId}
//                       setModalVisible={(val) => setShowSuccessMessage(val)}
//                     />
//                   </Modal>
//                 </div>
//               </Col>
//             </Row>
//           </Card>
//           <Col span={24} className="acc-section">
//             <div className="have-account">
//               <div className="acc-text">
//                 <p>
//                   <strong>Already have an account ?</strong>
//                 </p>
//               </div>
//               <div className="login-btn">
//                 <button onClick={openlogin}>Login</button>
//               </div>
//             </div>
//           </Col>
//           <Col span={24} className="footer-section">
//             <div className="register-footer">
//               <p className="register-para">
//                 The personal data administrator is AnjMal.in The data will be
//                 processed in order to enable the purchase of a ticket,
//                 consideration of complaints and for marketing purposes. The data
//                 will be made available to the carrier and supplier selected by
//                 the client. Providing data is optional, but it is a condition
//                 for using the services.The customer has the right to access the
//                 data content and the right to correct them.
//               </p>
//             </div>
//           </Col>
//         </div>
//       </div>
//       <Modal
//         className="login-modal"
//         visible={loginModal}
//         onOk={handleOkLogin}
//         onCancel={handleCancelLogin}
//         footer={false}
//         centered
//       >
//         <Login setModalVisible={(value) => setLoginModal(value)} />
//       </Modal>
//     </div>
//   );
// };

// export default AgentRegistration;

import { PlusOutlined } from "@ant-design/icons";
import React, { useState, useRef, } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Upload,
} from "antd";

import { Link } from "react-router-dom";
import APIClient from "../../helpers/ApiClient";
import moment from "moment";
import Login from "../Login/Login";
import OTP from "../OTP/Otp";
import CountryList from "../../common/CountryList";
import "./AgentRegistration.scss";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import currencyList from "../../common/navbar/currencyList";

const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const { Option } = Select;

const ImageUpload = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "application/pdf" ||
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, pdf,jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(true);
      return;
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={true}
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList
              ? fileList.length >= limitSize
                ? null
                : uploadLogoButton
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        destroyOnClose={true}
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};
const AgentRegistration = (props) => {
  const [pan, setPan] = useState([]);
  const [gst, setGst] = useState([]);
  const [otheruploads, setOtheruploads] = useState([]);
  const [userId, setUserId] = useState(-1);
  const [adhar, setAdhar] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [form] = Form.useForm();
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const { activeCurrency, setActiveCurrency } = useCurrencyContext();
  const mobile = useRef();
  // const areaCode = useRef();
  const mobileRef = useRef(null);
  const areaCodeRef = useRef(null);
  const handleAreaCodeChange = (value) => {
    setSelectedCountryCode(value);
    // When the area code changes, set the focus on the Mobile Number input field
    if (mobileRef.current) {
      mobileRef.current.focus();
    }
  };

  const onFinish = (values) => {
    //console.log(values,"hhhhh")
    let adharPath = "";

    if (values.AdhaarCardUpload) {
      if (values.AdhaarCardUpload.fileList.length > 0) {
        if (values.AdhaarCardUpload.fileList[0].response.status === 200) {
          adharPath =
            values.AdhaarCardUpload.fileList[0].response.data.filepaths[0];
        }
      }
    }
    let PANUpload = "";

    if (values.PANUpload) {
      if (values.PANUpload.fileList.length > 0) {
        if (values.PANUpload.fileList[0].response.status === 200) {
          PANUpload = values.PANUpload.fileList[0].response.data.filepaths[0];
        }
      }
    }
    let GSTUpload = "";

    if (values.GSTUpload) {
      if (values.GSTUpload.fileList.length > 0) {
        if (values.GSTUpload.fileList[0].response.status === 200) {
          GSTUpload = values.GSTUpload.fileList[0].response.data.filepaths[0];
        }
      }
    }
    let otherupload = "";

    if (values.OtherUploads) {
      if (values.OtherUploads.fileList.length > 0) {
        if (values.OtherUploads.fileList[0].response.status === 200) {
          otherupload =
            values.OtherUploads.fileList[0].response.data.filepaths[0];
        }
      }
    }

    let regObj = {
      Title: values.title,
      FirstName: values.firstname,
      LastName: values.lastname,
      Email: values.email,
      DialingCode: values.DialingCode,
      Mobile: values.phoneNumber,
      AgencyName: values.agencyname,
      Password: values.Password,
      DOB: values.dob,
      Gender: values.gender,
      StoreAddress: values.storeaddress,
      RegisterdAddress: values.registeredaddress,
      City:values.City,
      PAN: values.pannumber ? values.pannumber : "",
      PANUpload: PANUpload ? PANUpload : "",
      AdhaarCard: values.Aadhaar ? values.Aadhaar : "",
      AdhaarCardUpload: adharPath ? adharPath : "",
      GSTUpload: GSTUpload ? GSTUpload : "",
      Country: values.Country,
      Currency: values.Currency,
      LicenseNumber: values.LicenseNumber ? values.LicenseNumer : "",
      VoterId: values.VoterId ? values.VoterId : "",
      Passport: values.Passport ? values.Passport : "",
      PassportUpload: values.PassportUpload ? values.PassportUpload : "",
      OtherDocumentsUpload: otherupload ? otherupload : "",
      Comments: values.comments ? values.comments : "",
      Role: 5,
      Status: 0,
      Services: [1, 2, 3, 4, 5, 6],
      DeviceOS: 1,
      ApplicationType: 1,
      GSTDetails: {
        GSTCompanyName: "",
        GSTINNumber: "",
        GSTAdress: "",
        GSTPhone: "",
        GSTEmail: "",
      },
      CreadtedBy: 0,
      CreatedDate: 0,
      DeviceToken: "string",
      DeviceType: "string",
      FirBaseToken: "string",
      DeviceOSVersion: "string",
    };
    Submitdata(regObj);
    //console.log(regObj,"hhh")
    
  };
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: "USER",
  });
  const showModal1 = (type) => {
    /*============= check user logged or not =========== */
    setModalVisible({ visible: true, type: type });
  };
  const Submitdata = (data) => {
    APIClient.post("admin/b2b/signUp", data)
      .then((response) => {
        if (response.status == 200) {
          setUserId(response.data.userDetails.UserId);
          setShowSuccessMessage(true);
          form.resetFields();
          setAdhar([]);
          setPan([]);
          setOtheruploads([]);
          setGst([]);
          message.success(response.message);
        } else if (response.status == 500) {
          message.error("Duplicate Entry", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else if (response.status == 409) {
          message.error(response.message);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })

      .catch((error) => {
        console.error(error);
      });
  };

  const prefixSelector = (
    <Form.Item name="DialingCode" noStyle>
      <Select style={{ width: 80 }}  showSearch
       onChange={handleAreaCodeChange}
       ref={areaCodeRef}
       focusRef={mobile}>
        {CountryList.map((item) => (
          <Option key={item.dial_code} value={item.dial_code}>
            {item.dial_code}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const disabledOriginDate = (currentDate) => {
    return currentDate > moment().startOf("day");
  };

  const handleOk = (e) => {
    setShowSuccessMessage(false);
  };

  const handleCancel = (e) => {
    setShowSuccessMessage(false);
  };
  const enablebutton = (e) => {
    e.target.checked == true
      ? setButtonDisabled(false)
      : setButtonDisabled(true);
  };

  const openlogin = () => {
    setLoginModal(true);
  };

  const handleOkLogin = (e) => {
    setLoginModal(false);
  };

  const handleCancelLogin = (e) => {
    setLoginModal(false);
  };
  return (
    <div>
      <div className="register-section">
        <div className="reg-container">
          <h2> Partner Register account and travel with ease</h2>
          <Card className="register-card">
            <Form form={form} onFinish={onFinish} initialValues={{ DialingCode: "" }} size="large">
              <Row gutter={[16, 16]} className="agent-login-20">
                <Col md={6} sm={12} xs={24} className="right-panel">
                  <Form.Item
                    name="title"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Select placeholder="Title">
                      <Option value="Mr">Mr</Option>
                      <Option value="Ms">Ms</Option>
                      <Option value="Mrs">Mrs</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    name="firstname"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    name="lastname"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Required!" },
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Your Email" />
                  </Form.Item>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                      // {
                      //   minLength: 10,
                      //   maxLength: 10,
                      //   pattern: "^[0-9]{10}$",
                      //   message: "Must be 10 digits",
                      // },
                    ]}
                  >
                    <Input
                     ref={mobileRef}
                      addonBefore={prefixSelector}
                      style={{ width: "100%" }}
                      placeholder="Enter Mobile Number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={6} sm={12} xs={24}>
                  {/* <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Input.Password placeholder="Enter Password" />
                  </Form.Item> */}
                  <Form.Item
  name="Password"
  // label="Enter New Password"
  rules={[
    { 
      required: true, 
      message: "Password is required" 
    },
    {
      validator: (_, value) => {
        if (value.length < 8 || value.length > 24) {
          return Promise.reject(new Error('Password must be greater than 8 characters and include special characters & numbers'));
        }
        if (!/\d/.test(value)) {
          return Promise.reject(new Error('Password must contain at least one number'));
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
          return Promise.reject(new Error('Password must contain at least one special character'));
        }
        return Promise.resolve();
      },
    },
  ]}
>
  <Input.Password placeholder="Enter Password" />
</Form.Item>
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    name="dob"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <DatePicker
                      format="DD-MM-YYYY"
                      style={{ width: "100%" }}
                      disabledDate={disabledOriginDate}
                      placeholder="DOB"
                    />
                  </Form.Item>
                </Col>
                <Col md={6} sm={12} xs={24} className="right-panel">
                  <Form.Item
                    name="gender"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Select placeholder="Gender">
                      <Option value="1">Male</Option>
                      <Option value="2">Female</Option>
                      <Option value="3">Other</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="agent-login-21">
                <Col md={24} xs={24}>
                  <h6 className="other-docrr agency-21-trip">
                    Agency Information
                  </h6>
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    name="agencyname"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Input placeholder="Agency Name" />
                  </Form.Item>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    name="registeredaddress"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Input placeholder="Registered Address" />
                  </Form.Item>
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    name="City"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    name="Country"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Input placeholder="Country" />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={[16, 16]}
                className="agent-login-22 details-agent-bb"
              >
                 <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    name="Currency"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Select
      defaultValue={activeCurrency}
      onSelect={(val) => {
        setActiveCurrency(val);
      }}
      
      
    >
      {currencyList.map((item) => (
        <Option value={item.name} key={item.name}>
          {item.name}
        </Option>
      ))}
    </Select>
                  </Form.Item>
                </Col>
                 {selectedCountryCode === "+91" ?
                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    name="pannumber"
                    rules={[
                      {
                        pattern: "^[a-zA-Z0-9]{10}$",
                        message: "Must be 10 digits AlphaNumeric Number",
                      },
                    ]}
                  >
                    <Input placeholder="PAN Number" />
                  </Form.Item>
                </Col> :
                <Col md={6} sm={12} xs={24}>
                <Form.Item
                  name="pannumber"
                  rules={[
                    // {
                    //   pattern: "^[a-zA-Z0-9]{10}$",
                    //   message: "Must be 10 digits AlphaNumeric Number",
                    // },
                  ]}
                >
                  <Input placeholder="License Number" />
                </Form.Item>
              </Col>}
              {selectedCountryCode === "+91" ?
                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    name="Aadhaar"
                    rules={[
                      {
                        pattern: "^[0-9]{12}$",
                        message: "Must be 12 digits",
                      },
                    ]}
                  >
                    <Input placeholder="Aadhaar Number" />
                  </Form.Item>
                </Col> :
                <Col md={6} sm={12} xs={24}>
                <Form.Item
                  name="Aadhaar"
                  rules={[
                    // {
                    //   pattern: "^[0-9]{12}$",
                    //   message: "Must be 12 digits",
                    // },
                  ]}
                >
                  <Input placeholder="Govt Id Number" />
                </Form.Item>
              </Col>}
              {selectedCountryCode === "+91" ?
                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    name="gstnumber"
                    rules={[
                      {
                        pattern: "^[a-zA-Z0-9]{15}$",
                        message: "Must be 15 digits AlphaNumeric Number",
                      },
                    ]}
                  >
                    <Input placeholder="GST Number" />
                  </Form.Item>
                </Col> :
                <Col md={6} sm={12} xs={24}>
                <Form.Item
                  name="gstnumber"
                  rules={[
                    // {
                    //   pattern: "^[a-zA-Z0-9]{15}$",
                    //   message: "Must be 15 digits AlphaNumeric Number",
                    // },
                  ]}
                >
                  <Input placeholder="VAT Id Number" />
                </Form.Item>
              </Col> }
              </Row>

              <Row
                gutter={[16, 16]}
                className="agent-login-22 upload-item-agent"
                style={{justifyContent:"center"}}
              >
                <div className="upload-img-11">
                {selectedCountryCode === "+91" ?
                  <div className="doc-up">
                    <h6 className="other-docrr agent-card-21 mb-3">
                      PAN Details
                    </h6>
                    <ImageUpload
                      category="pan"
                      fileList={pan}
                      setFileList={setPan}
                      formName="PANUpload"
                      limit={true}
                      limitSize={1}
                    />
                  </div> : 
                  <div className="doc-up">
                  <h6 className="other-docrr agent-card-21 mb-3">
                  VAT Certificate
                  </h6>
                  <ImageUpload
                    category="pan"
                    fileList={pan}
                    setFileList={setPan}
                    formName="PANUpload"
                    limit={true}
                    limitSize={1}
                  />
                </div>}
                  {selectedCountryCode === "+91" ?
                  <div className="doc-up">
                    <h6 className="other-docrr agent-card-21 mb-3">
                      Aadhaar Details
                    </h6>

                    <ImageUpload
                      category="adhar"
                      fileList={adhar}
                      setFileList={setAdhar}
                      formName="AdhaarCardUpload"
                      limit={true}
                      limitSize={1}
                    />
                  </div> : 
                  <div className="doc-up">
                  <h6 className="other-docrr agent-card-21 mb-3">
                  Govt Id Proof
                  </h6>

                  <ImageUpload
                    category="adhar"
                    fileList={adhar}
                    setFileList={setAdhar}
                    formName="AdhaarCardUpload"
                    limit={true}
                    limitSize={1}
                  />
                </div>}
                {selectedCountryCode === "+91" ?
                  <div className="doc-up">
                    <h6 className="other-docrr agent-card-21 mb-3">
                      GST Details
                    </h6>
                    <ImageUpload
                      category="gst"
                      fileList={gst}
                      setFileList={setGst}
                      formName="GSTUpload"
                      limit={true}
                      limitSize={1}
                    />
                  </div> :
                  <div className="doc-up">
                  <h6 className="other-docrr agent-card-21 mb-3">
                  Trade License
                  </h6>
                  <ImageUpload
                    category="gst"
                    fileList={gst}
                    setFileList={setGst}
                    formName="GSTUpload"
                    limit={true}
                    limitSize={1}
                  />
                </div>}
                  <div className="doc-up">
                    <h6 className="other-docrr agent-card-21 agency-22-trip mb-3">
                      Other Documents
                    </h6>
                    <ImageUpload
                      category="otheruploads"
                      fileList={otheruploads}
                      setFileList={setOtheruploads}
                      formName="OtherUploads"
                      limit={true}
                      limitSize={6}
                    />
                  </div>
                </div>
              </Row>

              {/* <Row
                gutter={[16, 16]}
                className="agent-login-22 upload-item-agent"
              >
                <Col md={6} sm={12} xs={24}>
                  <h6 className="other-docrr agent-card-21 mb-3">
                    PAN Details
                  </h6>
                  <ImageUpload
                    category="pan"
                    fileList={pan}
                    setFileList={setPan}
                    formName="PANUpload"
                    limit={true}
                    limitSize={1}
                  />

                  <Form.Item
                    name="pannumber"
                    rules={[
                      {
                        pattern: "^[a-zA-Z0-9]{10}$",
                        message: "Must be 10 digits AlphaNumeric Number",
                      },
                    ]}
                  >
                    <Input placeholder="PAN Number" />
                  </Form.Item>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <h6 className="other-docrr agent-card-21 mb-3">
                    Aadhaar Details
                  </h6>

                  <ImageUpload
                    category="adhar"
                    fileList={adhar}
                    setFileList={setAdhar}
                    formName="AdhaarCardUpload"
                    limit={true}
                    limitSize={1}
                  />

                  <Form.Item
                    name="Aadhaar"
                    rules={[
                      {
                        pattern: "^[0-9]{12}$",
                        message: "Must be 12 digits",
                      },
                    ]}
                  >
                    <Input placeholder="Aadhaar Number" />
                  </Form.Item>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <h6 className="other-docrr agent-card-21 mb-3">
                    GST Details
                  </h6>
                  <ImageUpload
                    category="gst"
                    fileList={gst}
                    setFileList={setGst}
                    formName="GSTUpload"
                    limit={true}
                    limitSize={1}
                  />
                  <Form.Item
                    name="gstnumber"
                    rules={[
                      {
                        pattern: "^[a-zA-Z0-9]{15}$",
                        message: "Must be 15 digits AlphaNumeric Number",
                      },
                    ]}
                  >
                    <Input placeholder="GST Number" />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24} className="right-panel">
                  <h6 className="other-docrr agent-card-21 agency-22-trip mb-3">
                    Other Documents
                  </h6>
                  <ImageUpload
                    category="otheruploads"
                    fileList={otheruploads}
                    setFileList={setOtheruploads}
                    formName="OtherUploads"
                    limit={true}
                    limitSize={6}
                  />
                </Col>
              </Row> */}

              {/* <Row className="agent-login-22">
                <Col md={24} xs={24} className="right-panel">
                  <h6 className="other-docrr agency-22-trip mb-3">
                    Other Documents
                  </h6>
                  <ImageUpload
                    category="otheruploads"
                    fileList={otheruploads}
                    setFileList={setOtheruploads}
                    formName="OtherUploads"
                    limit={true}
                    limitSize={6}
                  />
                </Col>
              </Row> */}
              <Row justify="center" className="mt-2">
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject("Required"),
                    },
                  ]}
                >
                  <Checkbox>
                    <p className="mb-0">
                      I agree with{" "}
                      <Link to="/termsofconditions" target="_blank">
                        {" "}
                        Terms & conditions
                      </Link>{" "}
                      and{" "}
                      <Link to="/privacypolicy" target="_blank">
                        {" "}
                        Privacy Policy
                      </Link>
                    </p>
                  </Checkbox>
                </Form.Item>
              </Row>
              <Row justify="center">
                <Col md={24} xs={24}>
                  <Form.Item className="register-btn-container">
                    <Button
                      disabled={buttonDisabled}
                      type="primary"
                      onClick={() => form.submit()}
                    >
                      Register
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col span={8}>
                <div className="otp-modal-container">
                  <Modal
                    visible={showSuccessMessage}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={false}
                    className="otp-modal"
                  >
                    <OTP
                      userId={userId}
                      setUserId={setUserId}
                      setModalVisible={(val) => setShowSuccessMessage(val)}
                    />
                  </Modal>
                </div>
              </Col>
            </Row>
          </Card>
          <Col span={24} className="acc-section">
            <div className="have-account">
              <div className="acc-text">
                <p>
                  <strong>Already have an account ?</strong>
                </p>
              </div>
              <div className="login-btn" onClick={openlogin}>
              <button onClick={() => showModal1("AGENT")}> Login </button>
                {/* <button onClick={openlogin}>Login</button> */}
              </div>
            </div>
          </Col>
          <Col span={24} className="footer-section">
            <div className="register-footer">
              <p className="register-para">
                The personal data administrator is Anjmal.in The data will be
                processed in order to enable the purchase of a ticket,
                consideration of complaints and for marketing purposes. The data
                will be made available to the carrier and supplier selected by
                the client. Providing data is optional, but it is a condition
                for using the services.The customer has the right to access the
                data content and the right to correct them.
              </p>
            </div>
          </Col>
        </div>
      </div>
      <Modal
        className="login-modal"
        visible={loginModal}
        onOk={handleOkLogin}
        onCancel={handleCancelLogin}
        footer={false}
        centered
      >
        <Login setModalVisible={(value) => setLoginModal(value)}  type={modalVisible.type}/>
      </Modal>
    </div>
  );
};

export default AgentRegistration;

