import React from "react";
import "../NewTicket.scss";
import { Col, Row, Layout, Card,Image } from "antd";
import moment from "moment";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import ReactHtmlParser from "react-html-parser";
import { useCurrencyContext } from "../../providers/CurrencyProvider";
import { getVisaTicketStatus } from "../AllTicketStatus";
import LogoImg from "../../../assets/images/Anjmallogo.jpg";
let dateFormat = "MMM, DD-YYYY";

const TicketVisa = ({ ticketData}) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  let discount=0

  const totalAmount =Number(ticketData.visa.Fees);

  let promoData = JSON.parse(ticketData.promoData);
  if (promoData) {
    if (promoData.DiscountType == 1) {
      discount = Number((Number(totalAmount) / 100) * promoData?.Discount);
    } else {
      discount = Number(promoData.Discount);
    }
  }

  return (
    <>
    <span className="tic-gredient-f"></span>
    <Image style={{ width: 255, heigh: 200, marginTop:10,borderRadius:20 }} src={LogoImg} />
      <div className="cms-pages-width ">
        <Row>
          <Col md={24} xs={24}>
            <Row>
              <Col md={24} xs={24}>
                <p className="tic-city-name-d2">AnjMal</p>
                <Card className="card-tic-f3">
                  <Row>
                    <Col md={8} sm={24} xs={24}>
                      <div className="city-f3">
                        <p>{ticketData.visa.Country}</p>
                        <span>{ticketData.visa.Continent}</span>
                      </div>
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                      <div className="city-f4">
                        <p className="word-break">
                          {ticketData.ReferenceNumber}
                        </p>
                        <span>Visa Reference Number</span>
                      </div>
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                      <div className="city-f4">
                        <p className="word-break">
                          {ticketData.BookingConfirmationNumber}
                        </p>
                        <span>Booking Confirmation Number</span>
                      </div>
                    </Col>
                  </Row>
                  <div className="border-hr"></div>
                  <Row className="row-top-f4">
                    <Col md={7} xs={24} className="city-f4">
                      <p>{ticketData.visa.Id}</p>
                      <span>Visa ID</span>
                    </Col>
                    <Col md={7} xs={24} className="city-f4">
                      <p>{getVisaTicketStatus(ticketData.BookingStatus)}</p>
                      <span>Status</span>
                    </Col>
                    <Col md={6} xs={24} className="city-f4">
                      <p>{ticketData.passengers.length}</p>
                      <span>Number of Passengers</span>
                    </Col>
                    <Col md={4} xs={24} className="city-f4">
                      <p>paid</p>
                      <span>Payment Status</span>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-tic-f1">
                  <Row className="info-flight-t22">
                    <Col md={24} xs={24} className="city-tt-f4">
                      <p>Visa Details</p>
                      <span>
                        Here are the details of your Visa for{" "}
                        {ticketData.visa.VisaName}
                      </span>
                    </Col>
                  </Row>

                  {/* <Row>
                    <Col md={8} xs={24} className="city-tt-f5">
                      <div className="logo-f3-text">
                        <div className="sg-logo-flight">
                          <p>
                            <strong>{ticketData.visa.VisaName}</strong>
                          </p>

                          <p>{ticketData.visa.Country}</p>
                        </div>
                      </div>
                    </Col>
                    {/* <Col md={16} xs={24} className="city-tt-f4">
                      <div className="city-from-d3">
                        <div className="city-f55">
                          <p>
                            {moment(ticketData.customerDOB).format(dateFormat)}
                          </p>
                          <span>Date of Birth</span>
                        </div>
                        {/* Other details */}
                      {/* </div> */}
                    {/* </Col> */} 
                  {/* </Row> */}
                  <Row> 
                  <Col md={6} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>Visa Name</span>
                        <p>
                          <strong>{ticketData.visa.VisaName}</strong>
                        </p>
                      </div>
                    </Col>
                    <Col md={6} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>Passport Number</span>
                        <p>
                          <strong>{ticketData.passengers[0]?.passportNumber}</strong>
                        </p>
                      </div>
                    </Col>
                    <Col md={6} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>Visa Type</span>
                        <p>
                          <strong>{ticketData.visa.TypeofVisa}</strong>
                        </p>
                      </div>
                    </Col>
                     <Col md={6} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>Validity</span>
                        <p>
                          <strong>{ticketData.visa.Validity} Month(s)</strong>
                        </p>
                      </div>
                    </Col> 
                    <Col md={6} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>ProcessingTime</span>
                        <p>
                          <strong>{ticketData.visa.ProcessingTime} day(s)</strong>
                        </p>
                      </div>
                    </Col>
                     <Col md={6} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>Stayperiod</span>
                        <p>
                          <strong>{ticketData.visa.Stayperiod} Month(s)</strong>
                        </p>
                      </div>
                    </Col>
                    <Col md={6} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>Entry</span>
                        <p>
                          <strong>{ticketData.visa.Entry} </strong>
                        </p>
                      </div>
                    </Col> 
                    <Col md={6} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>Applied Date</span>
                        <p>
                          <strong>{moment(ticketData.createdDate).format(dateFormat)} </strong>
                        </p>
                      </div>
                    </Col> 
                  </Row>
                </Card>

                <Card className="card-tic-f2">
                  <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      <p>Contact Details</p>
                    </Col>
                  </Row>

                  <Row className="details-airlines-contact">
                    <Col md={8} sm={12} xs={24} className="contact-person-d4">
                      <span>Name</span>
                      <p>
                        {" "}
                        {ticketData.customerFirstName} {ticketData.customerLastName}
                      </p>
                    </Col>
                    <Col md={8} sm={12} xs={24} className="contact-person-d4">
                      <span>Mobile Number</span>
                      <p>{ticketData.customerMobile}</p>
                    </Col>

                    <Col md={8} sm={12} xs={24} className="contact-person-d4">
                      <span>Email</span>
                      <p>{ticketData.customerEmail}</p>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-tic-f2">
                  <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      <p>Passengers Details</p>
                    </Col>
                  </Row>
                  {ticketData.passengers.length > 0
                    ? ticketData.passengers.map((passenger) => (
                        <Row className="details-airlines-contact" key={passenger.Id}>
                          <Col md={8} xs={24} className="contact-person-d4">
                            <span>Name</span>
                            <p>
                              {passenger.firstName} {passenger.lastName}
                            </p>
                          </Col>
                          <Col md={8} xs={24} className="contact-person-d4">
                            <span>Date of Birth</span>
                            <p>{moment(passenger.dob).format(dateFormat)}</p>
                          </Col>
                          <Col md={8} xs={24} className="contact-person-d4">
                            <span>Nationality</span>
                            <p>{passenger.nationality}</p>
                          </Col>
                          {/* <Col md={6} xs={24} className="contact-person-d4">
                            <span>Passport Number</span>
                            <p>{passenger.passportNumber}</p>
                          </Col> */}
                          
                        </Row>
                      ))
                    : null}
                </Card>
                
                <Card className="card-tic-f2">
                  <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      <p>Fare Details</p>
                    </Col>
                  </Row>

                  <Row className="details-airlines-contact">
                    <Col md={18} xs={8} sm={12} className="contact-person-d4">
                      <p>Base Fare</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <p>
                      {ticketData?.currency}  {parseFloat((Number(ticketData.visa.Fees) / Number(ticketData?.currencyRatio)).toFixed(2))}
                      </p>
                    </Col>
                  </Row>
                   {discount > 0 ?
                  <Row className="details-airlines-contact">
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Discount</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <p>  {ticketData?.currency}  {parseFloat((Number(discount) / Number(ticketData?.currencyRatio)).toFixed(2))}</p>
                    </Col>
                  </Row>
                   : null } 
                  <Row className="details-airlines-contact">
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Total</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <p>
                      {ticketData?.currency}  {parseFloat((Number(ticketData.totalAmount) / Number(ticketData?.currencyRatio)).toFixed(2))}
                      </p>
                    </Col>
                  </Row>
                </Card>


                {/* {ticketData.PGStatus === 1 ? (
                  <Card className="insurance-section card-tic-f2">
                    <h5 style={{ fontSize: "16px" }}>Payment Gateway Status</h5>
                    <p>Payment Gateway Status: Success</p>
                  </Card>
                ) : (
                  <Card className="insurance-section card-tic-f2">
                    <h5 style={{ fontSize: "16px" }}>Payment Gateway Status</h5>
                    <p>Payment Gateway Status: Failed</p>
                  </Card>
                )} */}

                 <Card className="card-tic-f2">
                  <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      <p>Additional Information</p>
                    </Col>
                  </Row>

                  <Row className="details-airlines-contact booking-flight-details-info pt-0">
                    <Col md={24} xs={24} className="contact-person-d4">
                      {ticketData.visa.OverView ? (
                        <div className="termCon_wrapper">
                          <div className="description">
                            <p className="title">Visa Overview</p>
                            {ReactHtmlParser(ticketData.visa.OverView)}
                          </div>
                        </div>
                      ) : (
                        <div className="termCon_wrapper">
                          <p>
                            * Additional information can be displayed here.
                          </p>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card> 
              </Col>
            </Row>
          </Col>

          <Col md={6} xs={24}></Col>
        </Row>
      </div>
    </>
  );
};

export default TicketVisa;


