import React, { useState, useEffect } from "react";
import "./FlightsList.scss";
import SeperatedView from "./SeperatedView/SeperatedView";
import CombinedView from "./CombinedView/CombinedView";
import { useHistory } from "react-router-dom";
import { Modal, Button } from 'antd';
import APIClient from "../../helpers/ApiClient";
const FlightsList = ({ setSelectedTripType, flightAirSearchResp, updateSelectedFlight, flightSearchObj, updateFlightFares, selectedFlight, showNetFare, promoData, setMultiCityIndex, MultiCityIndex }) => {
  const [resultsType, setResultsType] = useState(null);
  const history = useHistory();
  const [journeyType, setJourneyType] = useState("onward");
  const [Mindex, setMindex] = useState(0);
  const [combinedOnewayFlightList, setCombinedOneWayFlightList] = useState([]);
  const [combinedTwowayFlightList, setCombinedTwoWayFlightList] = useState([]);

  let selectedFlightData = updateSelectedFlight ? updateSelectedFlight : null;
 
  const changeFlightResultsView = (flightObj) => {
    // console.log(flightAirSearchResp,"airresp");
    if (flightObj.length === 0) {
      selectedFlightData([]);
      return;
    }
    selectedFlightData(flightObj);

    if (flightAirSearchResp.resultsType.toUpperCase() === "SEPARATE") {
      if (journeyType.toUpperCase() === "ONWARD" && flightAirSearchResp.airTravelType.toUpperCase() != "MULTIDESTINATION") {
        changeReturnFlights();
        let backVisibleData = flightAirSearchResp.ibFlightDetails.filter((item) => item.isVisible);
        setReturnFlightResults(backVisibleData);
      } else {
        //changeMulticityIndex();
        if (selectedFlight.length < flightSearchObj.originDestinations.length - 1) {

          //if(selectedFlight.length+1<flightSearchObj.originDestinations.length){
          let backVisibleData = flightAirSearchResp.multicityFlightDetails[MultiCityIndex].filter((item) => item.isVisible);
          setReturnFlightResults(backVisibleData);
          changeMulticityIndex();
        }
      }
      //  else {
      //   history.push("/flight/checkout");
      // }
    } else {
      const API_URL = "flights/updatedAirPort/search/";
      if (flightSearchObj.airTravelType === "oneWay") {
        const searchOrigin = flightSearchObj.originDestinations[0].origin;
        const searchDestination = flightSearchObj.originDestinations[0].destination;
        const flightOrigin = flightObj[0].origin;
        const flightDestination = flightObj[0].destination;
        const flightOrigindescription = flightObj[0].originName;
        const flightDestinationdescription = flightObj[0].destiantionName;
        Promise.all([
          APIClient.get(`${API_URL}${searchOrigin}`),
          APIClient.get(`${API_URL}${searchDestination}`)
        ]).then(([originRes, destinationRes]) => {
         
          if (originRes.statusCode === 200 && destinationRes.statusCode === 200) {
            if (searchOrigin === flightOrigin && searchDestination === flightDestination) {
              history.push("/flight/checkout");
            } else {
              const searchoriginDescription = originRes.data.find(airport => airport.AirportCode ===  searchOrigin).AirportDesc || flightOrigin;
          const searchdestinationDescription = destinationRes.data.find(airport => airport.AirportCode === searchDestination).AirportDesc || flightDestination;
       
              showSectorChangeModal(searchOrigin, searchDestination,searchoriginDescription,searchdestinationDescription, flightOrigin, flightDestination,flightOrigindescription, flightDestinationdescription);
            }
          }
        }).catch((error) => {
          console.log("Error fetching data:", error);
        });
      
    
    } else if (flightSearchObj.resultsType.toUpperCase() === "COMBINED" &&
        flightSearchObj.airTravelType.toUpperCase() === "ROUNDTRIP") {
          const searchOrigin1 = flightSearchObj.originDestinations[0].origin;
    const searchDestination1 = flightSearchObj.originDestinations[0].destination;
    const searchOrigin2 = flightSearchObj.originDestinations[1].origin;
    const searchDestination2 = flightSearchObj.originDestinations[1].destination;
    const selectedOrigin1 = flightObj[0].origin;
    const selectedDestination1 = flightObj[0].destination;
    const selectedOrigin2 = flightObj[1].origin;
    const selectedDestination2 = flightObj[1].destination;
    const selectedOriginDescription1 = flightObj[0].originName;
    const selectedDestinationDescription1 = flightObj[0].destiantionName;
    const selectedOriginDescription2 = flightObj[1].originName;
    const selectedDestinationDescription2 = flightObj[1].destiantionName
  console.log(flightObj,"jjj")
    Promise.all([
      APIClient.get(`${API_URL}${searchOrigin1}`),
      APIClient.get(`${API_URL}${searchDestination1}`),
      APIClient.get(`${API_URL}${searchOrigin2}`),
      APIClient.get(`${API_URL}${searchDestination2}`)
    ]).then(([originRes1, destinationRes1, originRes2, destinationRes2]) => {
      if (originRes1.statusCode === 200 && destinationRes1.statusCode === 200 &&
          originRes2.statusCode === 200 && destinationRes2.statusCode === 200) {

        if (searchOrigin1 === selectedOrigin1 &&
            searchDestination1 === selectedDestination1 &&
            searchOrigin2 === selectedOrigin2 &&
            searchDestination2 === selectedDestination2) {
          history.push("/flight/checkout");
        } else {
          const searchOriginDescription1 = originRes1.data.find(airport => airport.AirportCode === searchOrigin1)?.AirportDesc || searchOrigin1;
          const searchDestinationDescription1 = destinationRes1.data.find(airport => airport.AirportCode === searchDestination1)?.AirportDesc || searchDestination1;
          const searchOriginDescription2 = originRes2.data.find(airport => airport.AirportCode === searchOrigin2)?.AirportDesc || searchOrigin2;
          const searchDestinationDescription2 = destinationRes2.data.find(airport => airport.AirportCode === searchDestination2)?.AirportDesc || searchDestination2;

          Modal.confirm({
            title: 'Sector Change',
            width:800,
            content: (
              <div>
    
                <p>You have searched for <span style={{ color: 'red' }}>{searchOriginDescription1}({searchOrigin1}) - {searchDestinationDescription1}({searchDestination1}) and {searchOriginDescription2}({searchOrigin2}) - {searchDestinationDescription2}({searchDestination2})</span>, however you selected <span style={{ color: 'green' }}>{selectedOriginDescription1}({selectedOrigin1}) - {selectedDestinationDescription1}({selectedDestination1}) and {selectedOriginDescription2}({selectedOrigin2}) - {selectedDestinationDescription2}({selectedDestination2})</span>.</p>
                <p>Are you sure you want to continue with the selected sectors?</p>
              </div>
            ),
            okText: 'CONTINUE',
            cancelText: 'CANCEL',
            onOk() {
              history.push("/flight/checkout");
            },
          });
        }
      }
    }).catch((error) => {
      console.log("Error fetching data:", error);
    });
        
       
    }
    else if(flightSearchObj.resultsType.toUpperCase() === "COMBINED" &&
    flightSearchObj.airTravelType.toUpperCase() === "MULTIDESTINATION"){
     
    //  console.log("multi",flightObj )
    const searchOrigins = flightSearchObj.originDestinations.map(dest => dest.origin);
    const searchDestinations = flightSearchObj.originDestinations.map(dest => dest.destination);
    const selectedOrigins = flightObj.map(flight => flight.origin);
    const selectedDestinations = flightObj.map(flight => flight.destination);
    const selectedOriginDescriptions = flightObj.map(flight => flight.originName);
    const selectedDestinationDescriptions = flightObj.map(flight => flight.destiantionName);

    Promise.all([
      ...searchOrigins.map(origin => APIClient.get(`${API_URL}${origin}`)),
      ...searchDestinations.map(destination => APIClient.get(`${API_URL}${destination}`))
    ]).then((responses) => {
      const [originRes1, originRes2, destinationRes1, destinationRes2] = responses;
      if (originRes1.statusCode === 200 && destinationRes1.statusCode === 200 &&
          originRes2.statusCode === 200 && destinationRes2.statusCode === 200) {

        let isMatch = searchOrigins.every((origin, index) =>
          origin === selectedOrigins[index] && searchDestinations[index] === selectedDestinations[index]);

        if (isMatch) {
          history.push("/flight/checkout");
        } else {
          const searchOriginDescriptions = searchOrigins.map((origin, index) => 
            responses[index].data.find(airport => airport.AirportCode === origin)?.AirportDesc || origin);
          const searchDestinationDescriptions = searchDestinations.map((destination, index) =>
            responses[index + searchOrigins.length].data.find(airport => airport.AirportCode === destination)?.AirportDesc || destination);

          showSectorChangeModal1(
            searchOrigins,
            searchDestinations,
            searchOriginDescriptions,
            searchDestinationDescriptions,
            selectedOrigins,
            selectedDestinations,
            selectedOriginDescriptions,
            selectedDestinationDescriptions
          );
        }
      }
    }).catch((error) => {
      console.log("Error fetching data:", error);
    });

    }
      else{
        history.push("/flight/checkout");
      }
    }
  };

const showSectorChangeModal1 = (
  searchOrigins,
  searchDestinations,
  searchOriginDescriptions,
  searchDestinationDescriptions,
  selectedOrigins,
  selectedDestinations,
  selectedOriginDescriptions,
  selectedDestinationDescriptions
) => {
  Modal.confirm({
      title: 'Sector Change Detected',
      width:800,
      content: (
          <div>
              <p>You have searched for the following sectors:</p>
              {searchOrigins.map((origin, index) => (
                  <p key={index}>
                      <span style={{ color: 'red' }}>
                          {searchOriginDescriptions[index]} ({origin}) - {searchDestinationDescriptions[index]} ({searchDestinations[index]})
                      </span> 
                      but selected 
                      <span style={{ color: 'green' }}>
                          {selectedOriginDescriptions[index]} ({selectedOrigins[index]}) - {selectedDestinationDescriptions[index]} ({selectedDestinations[index]})
                      </span>.
                  </p>
              ))}
              <p>Are you sure you want to continue with the selected sectors?</p>
          </div>
      ),
      okText: 'CONTINUE',
      cancelText: 'CANCEL',
      onOk() {
          // Proceed to checkout
          history.push("/flight/checkout");
      },
      onCancel() {
          //console.log('Cancel clicked');
      },
  });
};

  const showSectorChangeModal = (searchOrigin, searchDestination,searchoriginDescription,searchdestinationDescription, flightOrigin, flightDestination,flightOrigindescription, flightDestinationdescription) => {
    Modal.confirm({
      title: 'Sector Change',
      width:800,
      content: (
        <div>
          <p>You have searched for <span style={{ color: 'red' }}>{searchoriginDescription}({searchOrigin}) -{searchdestinationDescription}( {searchDestination})</span>, however you selected <span style={{ color: 'green' }}>{flightOrigindescription}({flightOrigin}) - {flightDestinationdescription}({flightDestination})</span></p>
          <p>Are you sure you want to continue with the selected sector?</p>
        </div>
      ),
      okText: 'CONTINUE',
      //okType: 'danger',
      cancelText: 'CANCEL',
      onOk() {
        // Handle the continue action (proceed with the selected sector)
        history.push("/flight/checkout");
      },
      onCancel() {
        // Handle the cancel action (cancel the sector change)
       // console.log('Cancel clicked');
      },
    });
  };
  const changeMulticityIndex = () => {
    //console.log("fty78",index)
    if (MultiCityIndex < flightSearchObj.originDestinations.length - 1) {
      setMultiCityIndex(MultiCityIndex + 1);
    }
  }
  useEffect(() => {
    if (flightSearchObj.airTravelType.toUpperCase() === "ONEWAY" || (flightSearchObj.airTravelType.toUpperCase() != "MULTIDESTINATION" && flightSearchObj.resultsType.toUpperCase() === "SEPARATE")) {
      if (journeyType.toUpperCase() == "ONWARD") {
        let onwardVisibleData = flightAirSearchResp.flightDetails.filter((item) => item.isVisible);
        setCombinedOneWayFlightList(onwardVisibleData);
      } else {
        let backVisibleData = flightAirSearchResp.ibFlightDetails.filter((item) => item.isVisible);
        setCombinedOneWayFlightList(backVisibleData);
        setSelectedTripType(2);
      }
    } else if (flightSearchObj.airTravelType.toUpperCase() == "MULTIDESTINATION" && flightSearchObj.resultsType.toUpperCase() === "SEPARATE") {

      let MultiVisibleData = flightAirSearchResp.multicityFlightDetails[MultiCityIndex].filter((item) => item.isVisible);

      setCombinedOneWayFlightList(MultiVisibleData);
      //changeMulticityIndex();
      // console.log(MultiVisibleData,"vb456");

    }
    else {
      let visibleData = flightAirSearchResp?.flightDetails?.filter((item) => item?.isVisible);
      setCombinedTwoWayFlightList(visibleData);
      // console.log(visibleData,"vb123");
    }
  }, [flightAirSearchResp]);

  useEffect(() => {
    setResultsType(flightAirSearchResp?.resultsType);
  }, []);

  const setOnwardFlights = () => {
    setSelectedTripType(1);
    setJourneyType("onward");
  };
  const changeMulticityResults = (index) => {
    setMindex(index)
    setMultiCityIndex(index)
  }
  const changeReturnFlights = () => {

    setSelectedTripType(2);
    setJourneyType("return");
  };
  const setReturnFlightResults = (flights) => {
    setCombinedOneWayFlightList(flights);
  };
  // console.log(flightAirSearchResp,combinedOnewayFlightList,resultsType,"hemu")
  return (
    <div className="result-bar">
      {resultsType != null ? (
        resultsType?.toUpperCase() === "COMBINED" ? (
          <CombinedView
            changeFlightResultsView={changeFlightResultsView}
            selectedFlight={selectedFlight}
            flightSearchObj={flightSearchObj}
            setFlightFareRules={updateFlightFares}
            combinedOnewayFlightList={combinedOnewayFlightList}
            combinedTwowayFlightList={combinedTwowayFlightList}
            showNetFare={showNetFare}
            promoData={promoData}
            traceId={flightAirSearchResp.traceId}
          />
        ) : (
          <SeperatedView
            flightSearchObj={flightSearchObj}
            selectedFlight={selectedFlight}
            combinedOnewayFlightList={combinedOnewayFlightList}
            changeFlightResultsView={changeFlightResultsView}
            setOnwardFlights={setOnwardFlights}
            promoData={promoData}
            changeReturnFlights={changeReturnFlights}
            setFlightFareRules={updateFlightFares}
            journeyType={journeyType}
            showNetFare={showNetFare}
            setMultiCityIndex={setMultiCityIndex}
            MultiCityIndex={MultiCityIndex}
            changeMulticityResults={changeMulticityResults}
            traceId={flightAirSearchResp.traceId}
          />
        )
      ) : null}
    </div>
  );
};

export default FlightsList;
